<script setup>
    import { computed, onMounted, onUnmounted, watch } from 'vue';

    const props = defineProps({
        show: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            default: '2xl',
        },
        closeable: {
            type: Boolean,
            default: true,
        },
    });

    const emit = defineEmits(['close']);

    watch(() => props.show, () => {
        if (props.show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = null;
        }
    });

    const close = () => {
        if (props.closeable) {
            emit('close');
        }
    };

    const closeOnEscape = (e) => {
        if (e.key === 'Escape' && props.show) {
            close();
        }
    };

    onMounted(() => document.addEventListener('keydown', closeOnEscape));

    onUnmounted(() => {
        document.removeEventListener('keydown', closeOnEscape);
        document.body.style.overflow = null;
    });

    const maxWidthClass = computed(() => {
        return {
            'sm': 'sm:max-w-sm',
            'md': 'sm:max-w-md',
            'lg': 'sm:max-w-lg',
            'xl': 'sm:max-w-xl',
            '2xl': 'sm:max-w-2xl',
            '3xl': 'sm:max-w-3xl',
            '4xl': 'sm:max-w-4xl',
            '80%': 'sm:max-w-[80%]',
        }[props.maxWidth];
    });
</script>

<template>
    <teleport v-if="show" to="body">
        <div aria-hidden="true"
             class="fixed z-[500] inset-0 bg-gray-500 bg-opacity-75 transition-opacity" @click.exact="() => close()"/>

        <div class="fixed inset-0 z-[500] w-screen overflow-y-auto" @click.exact="() => close()">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div
                    :class="maxWidthClass"
                    class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full"
                    @click.stop="">
                    <slot v-if="show"/>
                </div>
            </div>
        </div>
    </teleport>
</template>
